
.component-cart-settings-screen {
  display: flex;
  flex-direction: column;

  .component-cart-setting-item {
    margin-bottom: 1rem;
  }
}


.skeleton-container{

  > span{
    padding: .25rem;
    height: 200px;
    margin-bottom: 1rem;
  }

}

.banner-modal-footer{
  display: flex;
  .cancel-button{
    background-color: transparent;
    text-decoration: none;
    margin-right: 1rem;
  }
}
